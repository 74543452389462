* {
	margin: 0; 
}

body {
	
	height: auto;
	width: auto;
} 

/* Header */
.nav {
	background: #ffff;
	border-bottom: 0.5px solid rgb(231, 228, 228);  
	
}

a, .navbar-nav .nav-link {
	font-size: 18px;
	color: #565252;
	font-family: 'Lexend', sans-serif;
	
	&:hover {
		color: rgb(200, 7, 88);
	}
}


.brand1{
	padding-left: 20px;
}
.brand2 {
	padding-right: 20px;
} 



/* Home */
.home {
	padding-top: 100px;
}

/* Footer */
.footer {
	background-color: #cf9ab9;
	height: 120px;
}
.container {
	padding: 20px 0 10px 0;
}
.footer p {
	text-align: center;
}


/* Presentation */
.wrapper {
	height: auto;
	margin: 20 20px;
	position: relative;
}
.row-present {
	display: flex;
	justify-content: center;
	justify-items: center;
}

.image-present {
	padding-left: 80px;
	padding-top: 50px;
}

h4 {
	font-family: 'Gabriela', serif;
	padding-top: 50px;
}


/* TITLE */
h1, h2 {
	padding-top: 50px;
	text-align: center;
	position: relative;
	font-family: 'Gabriela', serif;
}


/* LINE */

.hr-theme-slash-2 {
	display: flex;
	justify-content: center;
	
	.hr-line {
		width: 80px;
	  position: relative;
	  margin: 15px;
	  border-bottom: 2px solid rgb(119, 109, 94);
	}
	.hr-icon {
	  position: relative;
	  top: 5px;
	}
 }

/* HOURS */

table {
	margin: 0 auto;
}

td {
	padding: 10px;
	font-size: 25px;
	color: rgb(200, 7, 88);
	font-family: 'Courgette';
}

.hours {
	text-align: center;
	padding-top: 10px;
	font-size: 16px;
	color: rgb(119, 109, 94);
}

/* PRESTATION */
span {
	color: #000;
}

p {
	font-size: 17px;
	color: rgb(119, 109, 94);
	text-align: justify;
}

.col {
	padding-top: 20px;
}

.card {
	margin: 10px auto;
	padding: 5px;
}
.title_text {
	text-align: center;
	color: #a69587;
	font-family: 'Gabriela', serif;
	letter-spacing: 0.8px;
}
.soin_visage {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 30%;
}

.button {
	border: none;
	background-color:#fff;
padding-left: 50px;

	&:hover {
		color: rgb(200, 7, 88);
	}
}

/* PRODUCTS */
.model {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
	visibility: hidden;
	opacity: 0;
	transform: scale(0);
	overflow: hidden;
	z-index: 999;
}

.model.open {
	visibility: visible;
	opacity: 1;
	transform: scale(1);
}

.model img {
	width: auto;
	max-width: 100%;
	height: auto;
	max-height: 100%;
	display: block;
	line-height: 0;
	box-sizing: border-box;
	padding: 120px 0 20px;
	margin: 0px auto;
}

.model.open svg {
	position: fixed;
	top: 100px;
	right: 40px;
	padding: 50px;
	color: #000;
	cursor: pointer;
}

/* Contact */
.contacts, .map {
	padding-top: 20px;
	padding-bottom: 20px;
}
h5 {
	text-align: center;
}
.phone, .mail, .map {
	display: block;
    margin-left: auto;
    margin-right: auto;
	
}

/* OFFRRES */
h3 {
	text-align: center;
	font-family: 'Lexend', sans-serif;
	color: #000;
}


/* Cards */
.col-gift {
	display: flex;
	justify-content: center;
	padding: 50px;
}
.gift {
	border: 1px solid;
}

h6 {
	text-align: center;
	font-family: 'Lexend', sans-serif;
	color: rgb(119, 109, 94) !important;
	padding: 10px;
}

/* .snowfall {
	position: absolute;
	top: 50px;
} */
/* MEDIA */

@media (max-width:884px) {
	.image-present {
		width: 600px;
	}
	img.card {
		max-width: 300px;
		height: 300px;
	}
	.map {
		width: 70%;
	}
}

@media (max-width:428px) {
	.footer {
		width: 100%;
	}
	.card {
		max-width: 20rem;
	}
	h4 {
		padding-top: 30px;
	}
}

@media (max-width: 390px) {
	.image1 {
		width: 350px;
		height: 350px;
	}
	.footer {
		width: 100%;
	}
}

@media (max-width: 320px) {
	
	.footer {
		width: 100%;
	}
	.card {
		max-width: 18rem;
	}
	img.card {
		max-width: 200px;
		height: 200px;
	}
}

@media (max-width: 311px) {
	.home {
		padding-top: 180px;
	}
}

@media (max-width: 280px) {
	img.card {
		max-width: 200px;
		height: 200px;
	}
	.footer {
		height: 100%;
	}
}
